import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";


export const getUpcomingEvent = createAsyncThunk("getUpcomingEvent", async () => {
    const response = await API.get('dashboard/upcomingevent');
    return response.data;
});

export const getSectionWiseSongs = createAsyncThunk("getSectionWiseSongs", async ({ page, limit }) => {
    const response = await API.get('song/list', {
        params: { page, limit }
    });
    return response.data;
});
