export function formatDateToDMY(dateString, type) {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  if (type) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}
export const download = async (url) => {
  const image = await fetch(url);
  const nameSplit = url.split("/");
  const duplicateName = nameSplit.pop();


  const imageBlob = await image.blob();
  const imageURL_link = URL.createObjectURL(imageBlob);
  const link = document.createElement("a");
  link.href = imageURL_link;
  link.download = "" + duplicateName + "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function getFormattedTime(dateString) {
  const date = new Date(dateString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter?.format(date);
}

export const formatSimpleDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date)) {
      throw new Error("Invalid date input");
    }

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const year = date.getFullYear();

    return `${day}, ${month} ${dayOfMonth}, ${year}`;
  } catch (error) {
    console.error("Error formatting date:", error.message);
    return "-";
  }
};

export function formatDate(inputDate) {
  // Convert the input to a Date object if it's not already
  const date = new Date(inputDate);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[date.getMonth()];

  // Get the appropriate suffix for the day of the month (st, nd, rd, th)
  const suffix =
    dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? "st"
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
        ? "nd"
        : dayOfMonth % 10 === 3 && dayOfMonth !== 13
          ? "rd"
          : "th";

  // Check if the provided date is today
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  return `${dayOfWeek}, ${dayOfMonth}${suffix} ${month}${isToday ? " (Today)" : ""
    }`;
}

export function genderfunc(val) {
  if (val == 0) {
    return "Male";
  } else if (val == 1) {
    return "Female";
  } else if (val == 2) {
    return "Unisex";
  } else {
    return "-";
  }
}

export const formattedDate = (dateString) => {
  if (!dateString) return "Invalid Date";
  const date = new Date(dateString);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const capitalize = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return 'N/A';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getGreeting = () => {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good afternoon";
  } else if (currentHour >= 18 && currentHour < 21) {
    return "Good evening";
  } else {
    return "Good evening";
  }
}

export const formatEventDetails = (eventDate, eventTime) => {
  try {
    const date = new Date(eventDate);
    const fromTime = new Date(eventTime.from);
    const toTime = new Date(eventTime.to);

    if (isNaN(date) || isNaN(fromTime) || isNaN(toTime)) {
      throw new Error("Invalid date or time input");
    }

    const formatTime = (date) => {
      const hours = date.getUTCHours(); // Ensure we're using UTC time
      const minutes = date.getUTCMinutes(); // Ensure we're using UTC time
      const isPM = hours >= 12;

      const hour12 = hours % 12 || 12;
      const minuteFormatted = minutes < 10 ? `0${minutes}` : minutes;

      const ampm = isPM ? 'PM' : 'AM';

      return `${hour12}:${minuteFormatted} ${ampm}`;
    };

    const formatDate = (date) => {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      const dayName = days[date.getUTCDay()]; // Get day name
      const monthName = months[date.getUTCMonth()]; // Get month name
      const dayOfMonth = String(date.getUTCDate()).padStart(2, '0'); // Add leading zero
      const year = date.getUTCFullYear(); // Get year

      return `${dayName}, ${monthName} ${dayOfMonth}, ${year}`;
    };

    const formattedDate = formatDate(date);
    const formattedTime = `${formatTime(fromTime)} - ${formatTime(toTime)}`;

    return { formattedDate, formattedTime };
  } catch (error) {
    console.error("Error formatting event details:", error.message);
    return { formattedDate: "-", formattedTime: "-" };
  }
};

export function formatDateTime(inputDate) {
  const date = new Date(inputDate);
  const now = new Date();

  // Helper function to format time as `3:04 PM`
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${minutes} ${amPm}`;
  };

  // Check if the input date is today
  const isToday =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();

  if (isToday) {
    return formatTime(date);
  }

  // Check if the input date is yesterday
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const isYesterday =
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate();

  if (isYesterday) {
    return `Yesterday ${formatTime(date)}`;
  }

  // For earlier dates, return as `MM/DD/YYYY HH:MM AM/PM`
  const formattedDate = date.toLocaleDateString(); // `MM/DD/YYYY` format
  return `${formattedDate} ${formatTime(date)}`;
}