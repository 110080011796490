import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";


export const getAllSongs = createAsyncThunk("getAllSongs", async ({ page, limit, type, recomended, eventId }) => {
    const response = await API.get('dashboard/songs', {
        params: { page, limit, type, recomended, eventId }
    });
    return response.data;
});


export const getAllCategorySongs = createAsyncThunk("getAllCategorySongs", async ({ page, limit, eventId, section }) => {
    const response = await API.get('song/selected', {
        params: { page, limit, section, eventId }
    });
    return response.data;
});

export const addSongAsPerSection = createAsyncThunk("addSongAsPerSection", async (data) => {
    const response = await API.post('song/event', data);
    return response.data;
});
export const addSongRequest = createAsyncThunk("addSongRequest", async (data) => {
    const response = await API.post('song/request', data);
    return response.data;
});
export const updateSongOrder = createAsyncThunk("updateSongOrder", async (data) => {
    const response = await API.put('song/reaarange', data);
    return response.data;
});

