export const JOIN_ROOM = "joinRoom";
export const USER_EVENTS_LISTING_EVENT = "my_events";
export const USER_GET_CHAT_EVENT = "get_chat";
export const USER_SEND_MESSAGE_EVENT = "send_message";

export const USER_EVENTS_LISTING_LISTENER = "events";
export const USER_GET_CHAT_LIST_LISTENER = "messages";
export const USER_GET_MESSAGE_LISTENER = "receive_message";
export const USER_JOINED_ROOM_LISTENER = "joinedRoomUser";


