import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";


export const userLogin = createAsyncThunk("userLogin", async (data) => {
    const response = await API.post('auth/login', data);
    return response.data;
});

export const verifyOtp = createAsyncThunk("verifyOtp", async (data) => {
    const response = await API.post('auth/verify', data);
    return response.data;
});

export const sendForgetPasswordMail = createAsyncThunk("sendForgetPasswordMail", async (data) => {
    const response = await API.post('auth/forgot', data);
    return response.data;
});

export const resetPassword = createAsyncThunk("resetPassword", async (data) => {
    const response = await API.put('auth/reset', data);
    return response.data;
});

export const resendOtp = createAsyncThunk("resendOtp", async (data) => {
    const response = await API.post('auth/resend-verification-otp', data);
    return response.data;
});

export const logoutAction = createAsyncThunk("logoutAction", async (data) => {
    const response = await API.post('auth/logout', data);
    return response.data;
});

export const setPassword = createAsyncThunk("setPassword", async (data) => {
    const response = await API.post('auth/set', data);
    return response.data;
});

export const userInfo = createAsyncThunk("userInfo", async () => {
    const response = await API.get('profile/info');
    return response.data;
});
export const getFaq = createAsyncThunk("getFaq", async ({ page, limit }) => {
    const response = await API.get('faq/list', {
        params: { page, limit }
    });
    return response.data;
});
export const getResources = createAsyncThunk("getResources", async ({ page, limit }) => {
    const response = await API.get('resource/list', {
        params: { page, limit }
    });
    return response.data;
});