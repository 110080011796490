import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getSectionWiseSongs } from "../Redux/Actions/eventData";
import { addSongRequest, getAllCategorySongs, updateSongOrder } from "../Redux/Actions/songs";
import { capitalize } from "../Utils/commonFiles";
import PaginationComponent from "../Components/Pagination";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import DragDropTable from "../Components/DragDropTable";
export default function SongList() {
  const Navigate = useNavigate();
  const dispatch = useDispatch()

  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get("eventId");
  const sectionId = urlParams.get("section");

  const songsAsperEventAndSection = useSelector((e) => e.eventAuth.sectionWiseSongList)
  const songAllList = useSelector((e) => e.songAuth.allCategorySongList)

  const [selectedSection, setSelectedSection] = useState(null);
  const [songsBySection, setSongsBySection] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [songsOrder, setSongsOrder] = useState(songAllList?.songs || []);
  const [show, setShow] = useState(false);
  const [secondShow, setSecondShow] = useState(false);

  const totalEntries = songAllList?.totalSongs ?? 0;
  const entriesPerPage = 10;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const songs = songsBySection[selectedSection] || [];
  const isSingleItem = songsOrder?.length === 1;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSecondClose = () => setSecondShow(false);
  const handleSecondShow = () => setSecondShow(true);

  const validationSchema = Yup.object({
    title: Yup.string().required("title is required"),
    artist: Yup.string().required("artist is required"),
    section: Yup.string().required("section is required"),
  });

  const initialValues = {
    title: "",
    artist: "",
    section: "",
    url: "",
  };

  const handlePageChange = (page) => {
    const eventId = songsAsperEventAndSection._id;
    const subSection = songsAsperEventAndSection.eventSection.eventSubSection.find(
      (subSection) => subSection.section.sectionName === selectedSection
    );
    const sectionId = subSection?.section?._id;

    if (sectionId) {
      const url = new URL(window.location.href);
      url.searchParams.set("eventId", eventId);
      url.searchParams.set("section", sectionId);
      url.searchParams.set("page", page);
      window.history.pushState({}, "", url);

      dispatch(getAllCategorySongs({
        eventId,
        section: sectionId,
        page,
        limit: entriesPerPage,
      }));
    }
    setCurrentPage(page);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }
    const reorderedSongs = Array.from(songsOrder);
    const [movedSong] = reorderedSongs.splice(source.index, 1);
    reorderedSongs.splice(destination.index, 0, movedSong);
    setSongsOrder(reorderedSongs);
    let orderedListIds = reorderedSongs.map((res) => res._id)

    let data = {
      eventId,
      eventType: songsAsperEventAndSection?.eventSection?.eventType?._id,
      section: sectionId,
      page: 0,
      limit: 10,
      songs: orderedListIds
    }

    setTimeout(() => {
      dispatch(updateSongOrder(data));
    }, 500)

  };

  const handleSubmit = async (values) => {
    values['event'] = eventId
    const subSection = songsAsperEventAndSection.eventSection.eventSubSection.find(
      (subSection) => subSection.section.sectionName === values.section
    );

    values.section = subSection?.section?._id;
    try {
      await dispatch(addSongRequest(values)).then(() =>
        handleSecondClose()
      )
    } catch (error) {
      // toast.error(error);
    }
  };

  const handleSectionClick = (sectionName) => {
    const eventId = songsAsperEventAndSection._id;
    const subSection = songsAsperEventAndSection.eventSection.eventSubSection.find(
      (subSection) => subSection.section.sectionName === sectionName
    );

    if (subSection) {
      const sectionId = subSection?.section?._id;

      setSelectedSection(sectionName);
      setCurrentPage(0);

      const url = new URL(window.location.href);
      url.searchParams.set("eventId", eventId);
      url.searchParams.set("section", sectionId);
      url.searchParams.set("page", 0);
      window.history.pushState({}, "", url);

      dispatch(getAllCategorySongs({
        eventId,
        section: sectionId,
        page: 0,
        limit: entriesPerPage,
      }));
    }
  };

  useEffect(() => {
    if (songsAsperEventAndSection) {
      const sections = {};
      songsAsperEventAndSection?.eventSection?.eventSubSection?.forEach(
        (subSection) => {
          const sectionName = subSection.section.sectionName;
          sections[sectionName] = subSection.songs;
        }
      );
      setSongsBySection(sections);

      if (Object.keys(sections).length > 0) {
        setSelectedSection(Object.keys(sections)[0]);
      }
    }
  }, [songsAsperEventAndSection]);

  useEffect(() => {
    dispatch(getSectionWiseSongs({ page: 0, limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (songAllList?.songs?.length) {
        setSongsOrder(songAllList?.songs)
      }
    }, 300)
  }, [songAllList])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const eventId = urlParams.get("eventId");
    const sectionId = urlParams.get("section");
    const page = Number(urlParams.get("page")) || 0;

    if (eventId && sectionId) {
      const section = songsAsperEventAndSection?.eventSection?.eventSubSection.find(
        (subSection) => subSection.section._id === sectionId
      );

      if (section) {
        setSelectedSection(section.section.sectionName);
        setCurrentPage(page);

        dispatch(getAllCategorySongs({
          eventId,
          section: sectionId,
          page,
          limit: entriesPerPage,
        }));
      }
    } else if (songsAsperEventAndSection) {
      const firstSection = songsAsperEventAndSection?.eventSection?.eventSubSection[0];
      if (firstSection) {
        const firstEventId = songsAsperEventAndSection._id;
        const firstSectionId = firstSection.section._id;

        setSelectedSection(firstSection.section.sectionName);
        setCurrentPage(0);

        const url = new URL(window.location.href);
        url.searchParams.set("eventId", firstEventId);
        url.searchParams.set("section", firstSectionId);
        url.searchParams.set("page", 0);
        window.history.pushState({}, "", url);

        dispatch(getAllCategorySongs({
          eventId: firstEventId,
          section: firstSectionId,
          page: 0,
          limit: entriesPerPage,
        }));
      }
    }
  }, [dispatch, songsAsperEventAndSection]);

  console.log(songsBySection, 'songsBySection');


  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="comm-top-title-sec">
          <h2>Song List</h2>
          <div className="home-welcome-txt">
            <button class="white-bg" onClick={handleSecondShow}>
              Make a song request
            </button>
            <button class="color-bg" onClick={() => {
              const eventId = songsAsperEventAndSection?._id;
              const selectedSubSection = songsAsperEventAndSection?.eventSection?.eventSubSection.find(
                (subSection) => subSection.section.sectionName === selectedSection
              );
              const sectionId = selectedSubSection?.section?._id;
              const maxSong = selectedSubSection?.section?.totalSongs

              if (eventId && sectionId) {
                Navigate(`/all-songs/${eventId}/${sectionId}/${currentPage}/${maxSong}`);
              }
            }}>
              Add More
            </button>
          </div>
        </div>
        <Row>

          <Col lg={4}>
            <div className="songlist-selection">
              <h4>Sections</h4>
              <p>Please select a section of your event to add songs to.</p>
              <ul>
                {Object.keys(songsBySection).map((section) => (
                  <li
                    key={section}
                    className={selectedSection === section ? "active" : ""}
                    onClick={() => handleSectionClick(section, selectedSection)}
                    style={{ cursor: "pointer" }}
                  >
                    {capitalize(section)}
                  </li>
                ))}
              </ul>
            </div>
          </Col>

          <Col lg={8}>
            {songAllList?.songs?.length === 0 ? (
              <div className="filters mt-0">
                <div className="inner-filter-field">
                  <div className="table-responsive">
                    <Table size="sm" className="table-cmn">
                      <thead className="d-none">
                        <tr>
                          <th>Song name</th>
                          <th>Artist</th>
                          <th>Genre</th>
                          <th>Duration</th>
                          <th>Arrange Order</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="5" className="no-data-found text-center">
                            <button
                              className="add-songs-btn"
                              onClick={() => {
                                const eventId = songsAsperEventAndSection?._id;
                                const selectedSubSection = songsAsperEventAndSection?.eventSection?.eventSubSection.find(
                                  (subSection) => subSection.section.sectionName === selectedSection
                                );
                                const sectionId = selectedSubSection?.section?._id;
                                const maxSong = selectedSubSection?.section?.totalSongs

                                if (eventId && sectionId) {
                                  Navigate(`/all-songs/${eventId}/${sectionId}/${currentPage}/${maxSong}`);
                                }
                              }}
                            >
                              Add Songs
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="filters mt-0">
                <div className="inner-filter-field">
                  <div class="table-responsive">
                    <Table size="sm" className="table-cmn">
                      <thead>
                        <tr>
                          <th>
                            <div className="user-checkbox">Song name</div>
                          </th>
                          <th>Artist</th>
                          <th>Genre</th>
                          <th>Duration</th>
                          <th>Arrange Order</th>
                        </tr>
                      </thead>
                      {!isSingleItem ? <DragDropTable songsOrder={songsOrder} handleDragEnd={handleDragEnd} />
                        : (
                          <tbody>
                            {songsOrder.map((song, index) => (
                              <tr key={song?.id || index}>
                                <td>{song?.title}</td>
                                <td>{song?.artist}</td>
                                <td>{song?.genre}</td>
                                <td>{song?.duration}</td>
                                <td>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 7H19"
                                      stroke="#979699"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M5 12H19"
                                      stroke="#979699"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M5 17H19"
                                      stroke="#979699"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                    </Table>

                    {songAllList?.totalSongs > 0 && (
                      <PaginationComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        totalEntries={totalEntries}
                        entriesPerPage={entriesPerPage}
                      />)}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a change</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Request
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={secondShow} onHide={handleSecondClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a Song</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ handleSubmit, errors, isValid }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <div className="modal-cmn-form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Title</Form.Label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter song title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Artist</Form.Label>
                      <Field
                        name="artist"
                        type="text"
                        className="form-control"
                        placeholder="Enter song artist"
                      />
                      <ErrorMessage
                        name="artist"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Section</Form.Label>
                      <Field
                        id="category"
                        as="select"
                        name="section"
                        className="form-select"
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Select section
                        </option>
                        {Object.entries(songsBySection).map(([key, value]) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="section"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Youtube or Spotify URL (Optional)</Form.Label>
                      <Field
                        name="url"
                        type="url"
                        className="form-control"
                        placeholder="Enter song url"
                      />
                    </Form.Group>
                  </div>
                  <Modal.Footer className="border-none justify-content-center">
                    <button className="secondary" onClick={handleSecondClose}>
                      Close
                    </button>
                    <button className="primary"
                      type="submit"
                    >
                      Add
                    </button>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
