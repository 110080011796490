import { createSlice } from "@reduxjs/toolkit";
import { addSongRequest, getAllCategorySongs, getAllSongs } from "../Actions/songs";
import { toast } from "react-toastify";


const initialState = {
    loading: false,
    songsList: [],
    allCategorySongList: []
}

const SongSlice = createSlice({
    name: "song",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllSongs.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.songsList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
        //===========================================================================================

        builder.addCase(getAllCategorySongs.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.allCategorySongList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // ========================================================================

        builder.addCase(addSongRequest.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(addSongRequest.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(addSongRequest.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

    },
});

export default SongSlice.reducer;