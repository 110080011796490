import { createSlice } from "@reduxjs/toolkit";
import { getFaq, getResources, userInfo } from "../Actions/auth";


const initialState = {
    loading: false,
    userList: [],
    faq: [],
    resources: []
}

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(userInfo.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.userList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
        builder.addCase(getFaq.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.faq = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
        builder.addCase(getResources.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.resources = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

    },
});

export default UserSlice.reducer;