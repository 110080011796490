// import React from 'react'
// import { Navigate, Outlet } from 'react-router-dom'

// const PrivatePublicRoute = () => {
//   const userToken = window.sessionStorage.getItem("userToken")

//   return (
//     !userToken ? <Outlet /> : <Navigate to={'/'} />
//   )
// }

// export default PrivatePublicRoute

// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';

// const PrivatePublicRoute = () => {
//   const userToken = sessionStorage.getItem('userToken');
//   const isVerified = JSON.parse(sessionStorage.getItem('isVerified') || "false"); 

//   if (userToken && isVerified) {
//     return <Navigate to="/home" />;
//   }

//   return <Outlet />;
// };

// export default PrivatePublicRoute;

import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivatePublicRoute = () => {
  const location = useLocation();
  const userToken = sessionStorage.getItem('userToken');
  const isVerified = JSON.parse(sessionStorage.getItem('isVerified') || "false");

  const allowedRoutesForVerifiedUsers = ["/set-password", "/reset-password", "/otp-verification"];

  const isAllowedRoute = allowedRoutesForVerifiedUsers.some((route) =>
    location.pathname.startsWith(route)
  );

  if (userToken && isVerified && !isAllowedRoute) {
    return <Navigate to="/home" />;
  }

    return <Outlet />;

};

export default PrivatePublicRoute;

