import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import SongTable from "./SongTable";
import { useDispatch, useSelector } from "react-redux";
import { addSongAsPerSection, getAllCategorySongs, getAllSongs } from "../Redux/Actions/songs";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { toast } from "react-toastify";

export default function AllSongs() {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { eventId, section, page, maxsongcount } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([]);

  const songLists = useSelector((e) => e.songAuth.songsList);

  const tabs = [
    { key: "all", label: "All Genres" },
    { key: "recomended", label: "Recommended" },
    { key: "Pop", label: "Pop" },
    { key: "Classical", label: "Classical" },
    { key: "Jazz", label: "Jazz" },
    { key: "Rock", label: "Rock" },
    { key: "Hip_hop", label: "Hip Hop" },
  ];

  useEffect(() => {
    dispatch(startstopLoading(true));

    const fetchParams = { page: 0, limit: 10 };

    if (activeTab === "recomended") {
      fetchParams.recomended = true;
    } else if (activeTab !== "all") {
      fetchParams.type = activeTab;
    }

    dispatch(getAllSongs(fetchParams)).then(() => {
      dispatch(startstopLoading(false));
    });
  }, [dispatch, activeTab]);


  useEffect(() => {
    const songs = songLists?.songs || [];
    let filtered = songs;

    if (activeTab === "recomended") {
      filtered = songs.filter((song) => song.recomended);
    } else if (activeTab !== "all") {
      filtered = songs.filter((song) => song.genre.toLowerCase() === activeTab.toLowerCase());
    }

    if (search) {
      filtered = filtered.filter((song) =>
        song.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredSongs(filtered);
  }, [activeTab, search, songLists]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const totalEntries = songLists?.total ?? 0;
  const entriesPerPage = 10;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(getAllSongs({ page: page, limit: 10 }))
  };

  useEffect(() => {
    // if user had already selected songs
    dispatch(getAllCategorySongs({
      eventId: eventId,
      section: section,
      page: page,
      limit: entriesPerPage,
    }));
  }, [dispatch])

  const handleSave = () => {
    if (selectedSongs.length > 0) {
      const payload = {
        eventId,
        section,
        songs: selectedSongs,
      };
      dispatch(addSongAsPerSection(payload)).then((res) => {
        if (res?.payload?.statusCode == 200) {
          navigate(-1)
          toast.success("Success")
        } else {
          toast.error(res?.payload?.message)
        }
      })
    } else {
      toast.error('No songs selected to save!');
    }
  };


  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="comm-top-title-sec">
          <h2>All Songs</h2>
        </div>

        <div className="mt-4">
          <Container fluid className="p-0">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="all"
              onSelect={handleTabChange}
            >
              <Row>
                <Col sm={12}>
                  <div className="tab-with-search">
                    <Nav variant="pills" className="comn-tabs-set">
                      {tabs?.map((tab) => (
                        <Nav.Item key={tab.key}>
                          <Nav.Link eventKey={tab.key}>{tab.label}</Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>

                    <div className="search-tab">
                      <div className="input-search">
                        <Form>
                          <Form.Group controlId="formBasicSearch">
                            <Form.Control
                              type="text"
                              placeholder="Search Songs"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </Form.Group>
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="8"
                              cy="8"
                              r="7"
                              stroke="#D61F7F"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M13 13L16 16"
                              stroke="#D61F7F"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                        </Form>
                      </div>
                      <div className="export-box">
                        <button onClick={handleSave}>Save</button>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={12}>
                  <Tab.Content>
                    {tabs.map((tab) => (
                      <Tab.Pane eventKey={tab.key} key={tab.key}>
                        <SongTable
                          total={songLists?.total}
                          songs={filteredSongs}
                          currentPage={currentPage}
                          totalEntries={totalEntries}
                          entriesPerPage={entriesPerPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                          setSelectedSongs={setSelectedSongs}
                          selectedSongs={selectedSongs}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </Container>
    </Layout>
  );
}

