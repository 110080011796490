import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { resetPassword } from "../Redux/Actions/auth"; 
import { toast } from "react-toastify";


const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams(); 

  const handleSubmit = (values) => {
    const resetData = {
      id: id,
      newPassword: values.newPassword,
    };

    dispatch(resetPassword(resetData)).then((res) => {
      if (res?.payload?.statusCode === 200) {
        navigate("/");
        toast.success("Password updated successfully.");
      } else {
        toast.error(res?.payload?.message || "Failed to update password.");
      }
    });
  };

  return (
    <div className="p-3">
      <Container fluid>
        <div className="row sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="reset-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    <img src={require("../Assets/Images/dark-logo.png")} alt="logo" />
                  </div>
                  <h2>Reset Password</h2>
                  <p className="mb-0">Please create a new strong password</p>

                  {/* Formik Form */}
                  <Formik
                    initialValues={{
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ handleSubmit }) => (
                      <FormikForm onSubmit={handleSubmit}>
                        <div className="form-set">
                          {/* New Password Field */}
                          <Form.Group className="mb-3" controlId="formBasicNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Field
                              name="newPassword"
                              type="password"
                              className="form-control"
                              placeholder="New Password"
                            />
                            <ErrorMessage
                              name="newPassword"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>

                          {/* Confirm Password Field */}
                          <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Field
                              name="confirmPassword"
                              type="password"
                              className="form-control"
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>

                        {/* Submit Button */}
                        <button type="submit" className="submit forgot-btn">
                          Update Password
                        </button>
                      </FormikForm>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
