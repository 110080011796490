import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Accordion from "react-bootstrap/Accordion";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { getFaq, getResources } from "../Redux/Actions/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { download } from "../Utils/commonFiles";

export default function Resources() {
  const dispatch = useDispatch()
  const faq = useSelector((e) => e.userAuth.faq)
  const resources = useSelector((e) => e.userAuth.resources)

  const Initialize = async () => {
    try {
      dispatch(startstopLoading(true));
      await dispatch(getFaq({ page: 0, limit: 10 }))
      await dispatch(getResources({ page: 0, limit: 10 }))
    } catch (error) {
      console.log(error);
    }
    finally {
      dispatch(startstopLoading(false));
    }
  }

  useEffect(() => {
    Initialize()
  }, []);

  return (
    <Layout className="main-wrap">
      <Container fluid className="mt-4 p-0">
        <div className="songlist-selection">
          <div className="resource-card-list">
            {resources.map((resourcesRes) =>
              <div className="resource-card">
                <div>
                  {/\.(png|jpe?g|gif)$/i.test(resourcesRes?.document) ?
                    <img src={resourcesRes?.document} alt="img" className="rounded-5" width={70} height={70} />
                    : <span>
                      <svg
                        width="28"
                        height="36"
                        viewBox="0 0 28 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.5014 7.47197L19.7217 1.05792C19.0006 0.37568 18.0567 0 17.064 0H4.15625C2.02388 0 0.289062 1.73482 0.289062 3.86719V32.1328C0.289062 34.2652 2.02388 36 4.15625 36H23.8438C25.9761 36 27.7109 34.2652 27.7109 32.1328V10.2812C27.7109 9.2231 27.2701 8.19914 26.5014 7.47197ZM24.4527 8.4375H19.2031C19.0093 8.4375 18.8516 8.27979 18.8516 8.08594V3.13847L24.4527 8.4375ZM23.8438 33.8906H4.15625C3.18699 33.8906 2.39844 33.1021 2.39844 32.1328V3.86719C2.39844 2.89793 3.18699 2.10938 4.15625 2.10938H16.7422V8.08594C16.7422 9.4429 17.8462 10.5469 19.2031 10.5469H25.6016V32.1328C25.6016 33.1021 24.813 33.8906 23.8438 33.8906Z"
                          fill="white"
                        />
                        <path
                          d="M21.5234 14.0625H6.05469C5.47222 14.0625 5 14.5347 5 15.1172C5 15.6997 5.47222 16.1719 6.05469 16.1719H21.5234C22.1059 16.1719 22.5781 15.6997 22.5781 15.1172C22.5781 14.5347 22.1059 14.0625 21.5234 14.0625ZM21.5234 19.6875H6.05469C5.47222 19.6875 5 20.1597 5 20.7422C5 21.3247 5.47222 21.7969 6.05469 21.7969H21.5234C22.1059 21.7969 22.5781 21.3247 22.5781 20.7422C22.5781 20.1597 22.1059 19.6875 21.5234 19.6875ZM11.1678 25.3125H6.05469C5.47222 25.3125 5 25.7847 5 26.3672C5 26.9497 5.47222 27.4219 6.05469 27.4219H11.1678C11.7503 27.4219 12.2225 26.9497 12.2225 26.3672C12.2225 25.7847 11.7503 25.3125 11.1678 25.3125Z"
                          fill="white"
                        />
                      </svg>
                    </span>}
                  <p>{resourcesRes.title}</p>
                </div>
                <button
                  onClick={() => download(resourcesRes?.document)}
                >Download</button>
              </div>
            )}
          </div>
        </div>
        <div className="songlist-selection mt-4">
          <div className="my-profile-title">
            <h4>FAQs</h4>
          </div>
          <div className="faq-list-accro">
            <Accordion defaultActiveKey="0" flush>
              {faq?.map((faqRes, index) =>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    {faqRes?.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    {faqRes?.description}
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </div>
        </div>
      </Container>
      <div className="pb-4">
        <p className="mb-0"></p>
      </div>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a change</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Request
          </button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={secondShow} onHide={handleSecondClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Request a Song</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Form>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Artist</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lorem ipsum dolor sit"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Youtube or Spotify URL (Optional)</Form.Label>
                  <Form.Control type="text" placeholder="https://" />
                </Form.Group>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer>
      </Modal> */}
    </Layout>
  );
}