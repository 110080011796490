import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Reducers/authSlice";
import globalReducer from "./Reducers/globalSlice"
import songReducer from "./Reducers/songSlice"
import eventReducer from "./Reducers/eventSlice"

export const store = configureStore({
  reducer: {
    globalSlice: globalReducer,
    userAuth: userReducer,
    songAuth: songReducer,
    eventAuth: eventReducer
  },
});
