import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OtpInput from "react-otp-input";
import { resendOtp, verifyOtp } from "../Redux/Actions/auth";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { AuthContext } from "../Components/AuthProvider";
import { Decryptedid } from "../Utils/bcrypt";

export default function OtpVerification() {

  const { updateToken } = useContext(AuthContext);
  const { id } = useParams()

  const Id = id && Decryptedid(atob(id));
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (otp) {
      setError("");
    }
  }, [otp]);

  const handleVerifyOtp = () => {
    if (otp.length !== 6) {
      setError("Please enter a 6-digit OTP.");
      return;
    }

    if (timer === 0) {
      setError("OTP has expired. Please resend.");
      return;
    }
    dispatch(startstopLoading(true));
    dispatch(verifyOtp({ otp }))
      .then((res) => {
        dispatch(startstopLoading(false));
        if (res?.payload?.statusCode === 200) {

           const token = res?.payload?.value?.token;
           const isVerified = res?.payload?.data?.isVerified;

          if (token ) {
            sessionStorage.setItem("userToken", token);
            sessionStorage.setItem("isVerified", isVerified);

            toast.success("OTP Verified Successfully");
            window.location.href = "/home";
          } else {
            toast.error("Invalid token data. Please try again.");
          }
        } else {
          toast.error(res?.payload?.message || "OTP verification failed");
        }
      })
      .catch((error) => {
        dispatch(startstopLoading(false))
        console.error("OTP Verification Error:", error);
        toast.error("An error occurred. Please try again.");
      });

  };

  const handleResendOtp = () => {
    setLoading(true);
    dispatch(startstopLoading(true));
    dispatch(resendOtp({ id: Id}))
      .then((res) => {
        dispatch(startstopLoading(false));
        if (res?.payload?.statusCode === 200) {
          const token = res?.payload?.data?.token;
          const expiryTime = new Date().getTime() + 10 * 60 * 1000;
          sessionStorage.setItem("userToken", token);
          // sessionStorage.setItem("tokenExpiry", expiryTime);

          setOtp("");
          setError("");
          setTimer(60);
          updateToken(token, expiryTime);
          toast.success("OTP Resent Successfully");


        } else {
          toast.error(res?.payload?.message || "OTP resend failed");
        }
      })
      .catch((error) => {
        dispatch(startstopLoading(false));
        console.error("Error resending OTP:", error);
        toast.error("An error occurred while resending OTP.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="p-3">
      <Container fluid>
        <div className="row sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="new-otp-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    <img src={require("../Assets/Images/dark-logo.png")} alt="Logo" />
                  </div>
                  <h2>OTP Verification</h2>
                  <p className="mb-0">
                    We’ve sent an OTP to your registered email.
                    <br />
                    Enter it below to continue.
                  </p>
                  <Form>
                    <div className="form-set set-otp">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        separator={<span style={{ width: "10px" }}></span>}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>

                    {error && <div
                      className="global-error text-danger p-2 mt-3"
                      style={{
                        backgroundColor: "#f8d7da",
                        border: "1px solid #f5c6cb",
                        borderRadius: "5px",
                      }}
                    >
                      <ul className="mb-0  text-center ">
                        <li style={{ listStyle: "none" }} className="h6 mb-0">
                          {error}
                        </li>
                      </ul>
                    </div>
                    }

                    <button
                      type="button"
                      className="submit forgot-btn"
                      onClick={handleVerifyOtp}
                    >
                      Verify
                    </button>

                    <div className="resend-mail">
                      {timer > 0 ? (
                        <p>Resend OTP in {formatTime(timer)} seconds</p>
                      ) : (
                        <>
                          <p>Didn't get the mail?
                            <Link
                              onClick={handleResendOtp}
                              disabled={loading}
                            >
                              {loading ? "Sending..." : " Resend"}
                            </Link>
                          </p>
                        </>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
