import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const userToken = sessionStorage.getItem('userToken');
  const isVerified = JSON.parse(sessionStorage.getItem('isVerified') || "false");

  if (!userToken || !isVerified) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default PrivateRoute;

