import { createSlice } from "@reduxjs/toolkit";
import { getSectionWiseSongs, getUpcomingEvent } from "../Actions/eventData";


const initialState = {
    loading: false,
    upcomingEvent: [],
    sectionWiseSongList: []
}

const EventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUpcomingEvent.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.upcomingEvent = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // ====================================================================================
        builder.addCase(getSectionWiseSongs.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.sectionWiseSongList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
    },
});

export default EventSlice.reducer;