import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import ResetPassword from "../Pages/ResetPassword";
import Error from "../Pages/Error";
import OtpVarification from "../Pages/OtpVarification";
import SignUp from "../Pages/SignUp";
import Clients from "../Pages/Clients";
import ForgotPassword from "../Pages/ForgotPassword";
import ClientsDetail from "../Pages/ClientsDetail";
import Events from "../Pages/Events";
import EventsDetails from "../Pages/EventsDetails";
import Transactions from "../Pages/Transactions";
import Songs from "../Pages/Songs";
import Administrators from "../Pages/Administrators";
import Home from "../Pages/Home";
import About from "../Pages/About";
import SongList from "../Pages/SongList";
import AllSongs from "../Pages/AllSongs";
import Resources from "../Pages/Resources";
import Messages from "../Pages/Messages";
import Payments from "../Pages/Payments";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "../Components/AuthProvider";
import SetPassword from "../Pages/SetPassword";
import PrivatePublicRoute from "./PrivatePublicRoute";
import RegisterComponent from "../Pages/RegisterComponent";


const RoutesPage = () => {
    return (
        <>
            <AuthProvider>
                <Router>
                    <Routes>
                        {/* public routes */}
                        <Route element={<PrivatePublicRoute />}>
                            <Route path="/" element={<Login />}></Route>
                            <Route path="/sign-up" element={<SignUp />}></Route>
                            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                            <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
                            <Route path="/set-password/:id" element={<SetPassword />}></Route>
                            <Route path="/error" element={<Error />}></Route>
                            <Route path="/otp-verification/:id" element={<OtpVarification />}></Route>
                        </Route>
                        {/* private routes */}
                        <Route element={<PrivateRoute />}>
                            <Route path="/home" element={<Home />}></Route>
                            <Route path="/about" element={<About />}></Route>
                            <Route path="/song-list" element={<SongList />}></Route>
                            <Route path="/all-songs/:eventId/:section/:page/:maxsongcount" element={<AllSongs />}></Route>
                            <Route path="/resources" element={<Resources />}></Route>
                            <Route path="/messages" element={<Messages />}></Route>
                            <Route path="/payments" element={<Payments />}></Route>
                            <Route path="/clients" element={<Clients />}></Route>
                            <Route path="/client-detail" element={<ClientsDetail />}></Route>
                            <Route path="/events" element={<Events />}></Route>
                            <Route path="/events-details" element={<EventsDetails />}></Route>
                            <Route path="/transactions" element={<Transactions />}></Route>
                            <Route path="/songs" element={<Songs />}></Route>
                            <Route path="/administrators" element={<Administrators />}></Route>
                        </Route>

                        {/* independent route */}
                        <Route path="/register-user" element={<RegisterComponent />}></Route>
                    </Routes>
                </Router>
            </AuthProvider>
        </>
    )
}

export default RoutesPage