import axios from 'axios';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  // headers: {
  //   Authorization: `Bearer ` + sessionStorage.getItem('userToken'),
  // },
});


API.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("userToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});



API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) {
      setTimeout(() => {
        sessionStorage.clear();
        window.location.reload(false);
        window.location.href = '/';
      }, 1000);
    }
    return error.response;
  }
);

export default API;
